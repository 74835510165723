import React, { FC, useEffect, useRef } from "react";
import { ContentWrap, Logo, LegalContent, Copy } from "./styled.index";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import IntroLogo from "../../assets/images/intro_logo_dark.svg";

const Legal: FC = () => {
  const ContentWrapRef = useRef(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: ContentWrapRef?.current || "",
      toggleActions: "play none none none",
      start: "top bottom",
      animation: gsap
        .timeline()
        .fromTo(
          ContentWrapRef?.current || null,
          0.75,
          { opacity: 0, y: 35 },
          { opacity: 1, y: 0 },
          "+=.1"
        ),
    });
  }, []);
  return (
    <ContentWrap ref={ContentWrapRef}>
      <Logo to={'/'}><img src={IntroLogo} alt="" /></Logo>
      <LegalContent>
        <h2 className={'heading fs-l'}>privacy policy</h2>
        <Copy>
          <div className="title">Introduction</div>
          <div>
            1380 Ocean Associates, LLC (“Company” or “we”) respects your privacy and is committed to protecting it through our compliance with this policy.<br /><br />
            This policy describes the types of information we may collect from you or that you may provide to us and our practices for collecting, using, maintaining, protecting, and disclosing that information.<br /><br />
            Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. This policy may change from time to time (see Changes to Our Privacy Policy, below).<br /><br />
          </div>

          <div className="title">Children Under the Age of 13</div>
          <div>
            This website, https://theresidencespompanobeach.com/ (our “Website”) is not intended for children under 13 years of age, and we do not knowingly collect personal information from children under 13. If we learn we have collected or received personal information from a child under 13 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 13, please email <a href="mailto:info@theresidencespompanobeach.com" target="_blank">info@theresidencespompanobeach.com</a><br /><br />
          </div>

          <div className="title">Information We Collect About You and How We Collect It</div>
          <div>
            <strong>“Personal Information”</strong> (also known as “personal data”) is information that identifies you as an individual or household, or relates to an identified or identifiable individual or household, including information that is about you individually and can be attributed to you even if it does not directly identify you, and information about your internet connection, the equipment you use to access our Website and usage details.<br /><br />
            Personal Information includes the following categories of information (examples listed are not exclusive, but illustrative):<br />
            <ul>
              <li><strong>Identity Data:</strong> name, username or similar identifier, title.</li>
              <li><strong>Contact Data:</strong> billing address, delivery address, email address, phone numbers.</li>
              <li><strong>Transaction Data:</strong> details about payments to and from you, other details of products and services you have purchased from us.</li>
              <li><strong>Technical Data:</strong> internet protocol (IP) address, login data, browser type and version, internet connection, time zone setting and location, browser plug-in types and versions, operating system and platform, logs, other technology on the devices you use to access this Website.</li>
              <li><strong>Account Data:</strong> information to activate and maintain your account, such as account number, account creation date, and last login date.</li>
              <li><strong>Behavioral and Preference Data:</strong> inferences or tendencies based on purchases or orders made by you, your interests, preferences, feedback or survey responses.</li>
              <li><strong>Usage Data:</strong> information about how you use our Website, such as date and time of your visit, clicks, browsing history, and website traffic data.</li>
              <li><strong>Marketing and Communications Data:</strong> your preferences in receiving marketing from us and our partners who are authorized to communicate with you, participation in promotions and other prize competitions, your communication preferences.</li>
              <li><strong>Social Media User Content Data:</strong> any social media information that you have shared publicly such as your profile, social media handles, social media interactions and public postings, your “likes” and reactions, your social media connections, your photos, images or videos that are public, or those you send to us by mentioning us or following our social media posts (whether using “handles” or “hashtags”), comments or messages you shared with us publicly or privately.</li>
              <li><strong>User Content:</strong> content provided or posted by you on social media platforms, in promotional emails and advertisements, and any other initiatives, including user-generated content (UGC).</li>
            </ul>
            We also collect limited personal information from our business contacts, vendors, and contractors. For example, we collect name and business contact information (including name, job title, telephone numbers (including mobile number), and email address) from each of our business contacts, vendors, and contractors for the purpose of initiating, operating, managing and maintaining our business relationships. As part of our relationship with our Business Contacts, we may also purchase Personal Information about you.<br /><br />
            We collect this information:<br />
            <ul>
              <li>Directly from you when you provide it to us.</li>
              <li>Automatically as you navigate through our Website. We use automatic data collection technologies to collect certain information about your computing equipment, mobile device, internet connection, IP address, operating system, browsing type, your browsing actions and patterns, and other ways you interact with our Website.</li>
              <li>From Social Media Interaction and Activity, such as Instagram, including any Social Media User Content Data or User Generated Content.</li>
              <li>
                From third parties.
                <ul>
                  <li>We receive your Personal information from third parties, including Identity Data, Contact Data, Transaction Data, and Marketing and Communications Data: from technical, payment and delivery services; from joint marketing partners; from fraud prevention providers.</li>
                </ul>
              </li>
            </ul>
            The information we collect automatically is only statistical data and does not include personal information, but we may maintain it or associate it with personal information we collect in other ways or receive from third parties. It helps us to improve our Website and to deliver a better and more personalized service, including by enabling us to:<br />
            <ul>
              <li>Estimate our audience size and usage patterns.</li>
              <li>Store information about your preferences, allowing us to customize our Website according to your individual interests.</li>
              <li>Speed up your searches.</li>
              <li>Recognize you when you return to our Website.</li>
            </ul>
            The technologies we use for this automatic data collection may include:<br />
            <ul>
              <li><strong>Cookies</strong> (or browser cookies). A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of our Website. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Website. For information about managing your privacy and security settings for cookies, see Choices About How We Use and Disclose Your Information.</li>
              <li><strong>Web Beacons.</strong> Pages of our Website and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity).</li>
            </ul>
          </div>

          <div className="title">Information from Other Sources</div>
          <div>
            We may receive information from third parties such as advertising and marketing partners, public databases, and social media platforms, to supplement the information we receive from our Website visitors. If we do so, this Privacy Policy governs how we can use that information, whether such use is independent of or in combination with information you have provided.<br /><br />
          </div>

          <div className="title">How We Use Your Information and Lawful Basis for Processing</div>
          <div>
            We have a lawful basis for processing your Personal Information, including fulfilling our obligations to you under a contract or to take steps at your request, for our legitimate interests (when balanced against your rights and freedoms), as required by applicable law, or with your consent. We use information that we collect about you or that you provide to us, including any personal information:<br /><br />
            <strong>1. To present our Website and its contents to you.</strong><br />
            Personal Information Collected: Identity Data; Technical Data; Usage Data; activities performed on our Website may include the collection of additional Personal Information(and legal bases), such as administration of your account and providing our products to you (see additional details below).<br /><br />
            Legal Basis:<br />
            <ul>
              <li>Legitimate interest, to ensure our Website is running efficiently and securely; to optimize our Website; to grow our business; to be relevant to our target consumers.</li>
              <li>Your consent, for the processing of personal data in connection with the use of cookies (except those cookies that are Strictly Necessary, for which your consent is not required as the legal basis for processing your personal data is our legitimate interest).</li>
            </ul>
            <strong>2. To provide you with information, products, or services that you request from us, including payment processing and notifications related to your purchases/potential purchases.</strong><br />
            Personal Information Collected: Identity Data; Technical Data; Usage Data; activities performed on our Website may include the collection of additional Personal Information(and legal bases), such as administration of your account and providing our products to you (see additional details below).<br /><br />
            Legal Basis:<br />
            <ul>
              <li>Legitimate interest, to ensure our Website is running efficiently and securely; to optimize our Website; to grow our business; to be relevant to our target consumers.</li>
              <li>Your consent, for the processing of personal data in connection with the use of cookies (except those cookies that are Strictly Necessary, for which your consent is not required as the legal basis for processing your personal data is our legitimate interest).</li>
            </ul>
            <strong>3. Provide you with customer support.</strong><br />
            Personal Information Collected: Identity Data; Contact Data; Transaction Data; Technical Data; Account Data; Usage Data; and any other information you provide to us in the course of addressing your inquiry.<br /><br />
            Legal Basis:<br />
            <ul>
              <li>Legitimate interest, in optimizing our services; addressing your inquiries and requests.</li>
              <li>Your consent, where required in connection with any informational or transactional communications you have opted-in to.</li>
              <li>Performance of a contract, in relation to any mutual agreement and our Website Terms of Use.</li>
            </ul>
            <strong>4. Serve you direct marketing communications, such as emails and text messages.</strong><br />
            Personal Information Collected: Identity Data; Contact Data; and Marketing and Communications Data<br /><br />
            Legal Basis:<br />
            <ul>
              <li>Your consent, in connection with any communications you have opted-in to.</li>
              <li>Legitimate interest, in providing information about products and services that may be of interest to you, unless applicable law requires us to obtain your consent, in which case we will do so.</li>
              <li>For users based in Italy: Art. 130 (4) of the Italian Privacy Code (so called “soft opt-in”), to send you e-mail communications in relation to products and services similar to products and services that you previously purchased with us. If you do not want to receive these communications, you can opt-out at any time.</li>
            </ul>
            <strong>5. Administer your account.</strong><br />
            Personal Information Collected: Identity Data; Contact Data; Account Data; and Transaction Data if you have utilized your account in connection with purchases.<br /><br />
            Legal Basis:<br />
            <ul>
              <li>Performance of a contract, in relation to any mutual agreement and our Website Terms of Use.</li>
              <li>Legitimate interest, in providing a more efficient customer experience.</li>
            </ul>
            <strong>6. Facilitate a contest, sweepstakes, campaign, or promotion.</strong><br />
            Personal Information Collected: Identity Data; Contact Data; Behavioral and Preference Data, Social Media User Content Data, and Marketing and Communications Data if included as part of the contest, sweepstakes, campaign, or promotion.<br /><br />
            Legal Basis:<br />
            <ul>
              <li>Performance of a contract, which is our agreement with you about the terms of the contest, sweepstakes, campaign, or promotion (some may have additional terms containing information about how we will use and disclose your Personal Information).</li>
              <li>Your consent, in connection with promotions you participate in that do not contain additional rules or contracts, and in sharing data with sponsors or partners in connection with the promotion.</li>
              <li>Your consent, in connection with activity we perform based on your behavior, preferences or social media activity.</li>
            </ul>
            <strong>7. Promote and market our business by better understanding your interests and preferences.</strong><br />
            Personal Information Collected: Identity Data; Contact Data; Transaction Data; Technical Data; Behavioral and Preference Data; Usage Data; and Social Media User Content Data.<br /><br />
            Legal Basis:<br />
            <ul>
              <li>Legitimate interest, in better understanding user needs and providing products and services that may be of interest to you based on aggregated audiences through interaction with our partners, unless applicable law requires us to obtain your consent, in which case we will do so.</li>
              <li>Your consent, for the processing of personal data in connection with our use of cookies (except for those that are Strictly Necessary, for which your consent is not required; in that case the legal basis for the processing of personal data is our legitimate interest), and sharing data with sponsors or partners in connection with the promotion.</li>
            </ul>
            <strong>8. Engage with you on social media channels.</strong><br />
            Personal Information Collected: Social Media User Content Data; User Content.<br /><br />
            Legal Basis:<br />
            <ul>
              <li>Legitimate interest, in being relevant to our consumers; social interaction and expanding our brand; responding to your questions and comments; and reporting on and analyzing user engagement.</li>
              <li>Your consent, in connection with any targeted advertising based on our and third-party use of cookies.</li>
            </ul>
            <strong>9. Display or utilize your user-generated content (UGC) provided to us by you or that has been shared publicly on social media platforms, in promotional emails and advertisements, and any other initiatives.</strong><br />
            Personal Information Collected: Identity Data; Contact Data; Social Media User Content Data; and any other User Content you provide.<br /><br />
            Legal Basis:<br />
            <ul>
              <li>Performance of a contract, in relation to any mutual agreement and our Website Terms of Use and any terms specific to UGC.</li>
              <li>Your consent, in connection with the content you provide to us.</li>
              <li>Legitimate interest, to grow our business; to be relevant to our consumers.</li>
            </ul>
            <strong>10. Develop and manage our business and operations, including data analysis, audits, developing new properties and services, analyzing user preferences to prepare aggregated reports on how our digital content is used, and improving our services.</strong><br />
            Personal Information Collected: Identity Data; Contact Data; Transaction Data; Behavioral and Preference Data; Technical Data; Behavioral and Preference Data; Usage Data; and Social Media User Content Data.<br /><br />
            Legal Basis:<br />
            <ul>
              <li>Legitimate interest, in understanding aggregated customer behavior, improving our selection of products and services, and exploring ways to develop and enhance our business.</li>
              <li>Your consent, for the processing of personal data in connection with our use of cookies (except for those cookies that are Strictly Necessary, for which your consent is not required; in that case the legal basis for the processing of personal data is our legitimate interest).</li>
            </ul>
            <strong>11. Comply with a legal matter, legal or regulatory requirement, or government request.</strong><br />
            Personal Information Collected: Any information as required by applicable legal authority, as part of a legal matter, or otherwise in compliance with legal or regulatory requirements.<br /><br />
            Legal Basis:<br />
            <ul>
              <li>Legal obligation, as required by law.</li>
            </ul>
            <strong>12. Fraud prevention, security, and asset protection, and for quality and safety assurance measures.</strong><br />
            Personal Information Collected: Identity Data; Contact Data; Transaction Data; Technical Data; Account Data; Behavioral and Preference Data; Usage Data; Marketing and Communications Data; and Social Media User Content Data.<br /><br />
            Legal Basis:<br />
            <ul>
              <li>Legitimate interest, in preventing fraud, error, negligence, breach of contract, and other illegal activities such as financial crime, cyberattacks, or identity theft; protecting and securing our Website, premises, customers, employees, and the public.</li>
            </ul>
            <strong>13. Enter into a business relationship with us as a company or private person, such as an Ambassador.</strong><br />
            Personal Information Collected: Identity Data; Contact Data; and other Personal Information you may provide to us.<br /><br />
            Legal Basis:<br />
            <ul>
              <li>Performance of a contract, in relation to any mutual agreement and our Website Terms of Use.</li>
            </ul>
            We may also disclose or transfer your Personal Information to a third party in the event of any financing, reorganization, merger, sale, joint venture, assignment, transfer, or other disposition of all or any portion of our business, assets, or stock (including in connection with any bankruptcy or similar proceedings). We do so pursuant to our legitimate interest of growing our business. We also aggregate and anonymize Personal Information so that it will no longer be considered Personal Information. We do so to generate other data for our use, which we may use and disclose for any purpose, as it no longer constitutes Personal Information.<br /><br />
          </div>

          <div className="title">Additional Information on the Legal Bases owe rely on to process your Personal Information:</div>
          <div>
            <ul>
              <li>Legitimate Interest – the interest of conducting and managing our business to enable us to give you the best service/product and the best and most secure experience. We balance the potential impact on you (both positive and negative) and your rights, and if the impact on your rights outweighs our legitimate business interests, we do not use or disclose your Personal Information without your consent or are otherwise required or permitted to by law. You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting Ashley Hamaoui at <a href="mailto:info@theresidencespompanobeach.com" target="_blank">info@theresidencespompanobeach.com</a>.</li>
              <li>Performance of Contract – processing your Personal Information where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.</li>
              <li>Comply with a legal or regulatory obligation – processing your Personal Information where it is necessary for compliance with a legal or regulatory obligation.</li>
              <li>Consent – you have given express consent to a certain use of your Personal Information.</li>
            </ul>
          </div>

          <div className="title">Disclosure of Your Information</div>
          <div>
            We may disclose aggregated information about our users, and information that does not identify any individual, without restriction.<br /><br />
            We may disclose personal information that we collect or you provide as described in this privacy policy:<br />
            <ul>
              <li>To our subsidiaries and affiliates.</li>
              <li>To contractors, service providers, and other third parties we use to support our business and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them, including the following Third Party Vendor service providers:</li>
              <li>Email Marketing – We use MRI Software, LLC as our marketing vendor. Through MRI Software, LLC we collect information related to marketing communications, including tracking the actions you take that assist us in measuring the performance of our Email Marketing. You may access MRI Software, LLC’s privacy policy <a href="https://www.mrisoftware.com/privacy-policy/" target="_blank">here</a>.</li>
              <li>Upon subscription, MRI Software, LLC tracks the pages you visit on the Website and, if you become a subscriber, information about your transaction is provided to MRI Software, LLC.</li>
              <li>Our marketing emails contain tracking pixels, allowing is to analyze their success. These tracking pixels allow us to see if and when you opened an email, which links you click in the email, and which pages of the Website you visit. This information is not provided to third parties.</li>
              <li>Email Subscription Service – We use Gravity Forms to collect your email address when you subscribe to our Marketing. No data entered into the Gravity Forms subscription box is provided to or accessed by Gravity Forms.</li>
              <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Company’s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by us about our Website users is among the assets transferred.</li>
              <li>For any other purpose disclosed by us when you provide the information.</li>
              <li>With your consent.</li>
            </ul>
            We may also disclose your personal information:<br /><br />
            <ul>
              <li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</li>
              <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Company, our customers, or others.</li>
            </ul>
          </div>

          <div className="title">Choices About How We Use and Disclose Your Information</div>
          <div>
            We strive to provide you with choices regarding the personal information you provide to us. We have created mechanisms to provide you with the following control over your information:<br />
            <ul>
              <li>Tracking Technologies and Advertising. You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. If you disable or refuse cookies, please note that some parts of this site may then be inaccessible or not function properly.</li>
            </ul>
          </div>

          <div className="title">Accessing and Correcting Your Information</div>
          <div>
            You may also contact Ashley Hamaoui at <a href="mailto:info@theresidencespompanobeach.com" target="_blank">info@theresidencespompanobeach.com</a> to request access to, correct, or delete any personal information that you have provided to us. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.<br /><br />
            You have rights under applicable data protection laws, including the right to access and update your Personal Information, restrict how it is used, transfer certain Personal Information to another controller, withdraw your consent at any time, and have us erase certain Personal Information about you. You also have the right to complain to a supervisory authority about our processing of your Personal Information.<br /><br />
            In accordance with the United Kingdom General Data Protection Regulation (UK GDPR), the European Union General Data Protection Regulation (EU GDPR), the California Privacy Protection Act (CPPA), and other privacy laws, you may have some or all of the following rights, in some cases subject to specific conditions as set forth in the applicable laws:<br /><br />
            <ul>
              <li>Right to Access. You have the right to obtain information from us as to whether we are processing your Personal Information, and to obtain a copy of that Personal Information (also referred as ‘data subject access rights’).</li>
              <li>Right to Rectification. You may have the right to have inaccurate or incomplete Personal Information corrected without undue delay by notifying us in any manner set forth below in “Contact Information” to ensure your Personal Information is accurate and as current as possible.</li>
              <li>Right to Erasure (or to be Forgotten). You may have the right to request that we delete your Personal Information under certain conditions, for example when our processing of that Personal Information is no longer necessary, or when your Personal Information was unlawfully processed. We may not accommodate a request to erase information under certain circumstances, such as if processing is necessary to comply with a legal obligation, or to establish or defend a legal claim.</li>
              <li>Withdrawing Consent. To the extent that our processing of your Personal Information is based on your consent, you have the right to withdraw your consent at any time. Withdrawing your consent will not, however, impact the lawfulness of data processed prior to withdrawal, and will not affect the lawfulness of continued processing based on any other lawful basis.</li>
              <li>Right to Restriction of Processing. You may have the right to restrict the processing of your Personal Information (meaning we can store, but not process, your Personal Information) under certain circumstances, such as preventing processing while you correct inaccuracies.</li>
              <li>Right to Object. You may have the right to object to the processing of your Personal Information for direct marketing purposes. You also have the right to object to processing based on our legitimate interests (or those of a third-party) based on certain personal situations. We will stop processing your Personal Information unless we can demonstrate compelling legitimate grounds which override your interests, rights, and freedoms, or for the establishment or defense of legal claims.</li>
              <li>Right to Data Portability. To the extent the Personal Information you provide is processed based on your consent or to perform a contract, you may have the right to request that we provide you a copy of all or part of such Personal Information in structured, commonly used, and machine-readable format. You also have the right to request that we transmit this Personal Information to another controller where technically feasible.</li>
              <li>Right to Digital Legacy: If you reside in France, you also have the right to set out (general or specific) instructions about what happens to your personal data after your death.</li>
            </ul>
          </div>

          <div className="title">Exercising These Rights</div>
          <div>
            You may exercise any of the above rights by contacting us as provided in the “contact information” section of this Notice. If you contact us to exercise any of the foregoing rights, we may need to ask you for additional information to verify your identity.<br /><br />
            Please note that if you make a manifestly unfounded or excessive request (as determined in our reasonable discretion), you may incur fees as provided by applicable law.<br /><br />
            <ul>
              <li>Right to lodge a complaint. If you are a citizen of the EU, you have the right to lodge a complaint with the applicable supervisory authority in the country where you live or work or where you believe your rights under applicable laws have been violated. A list of EU data protection authorities is available here, the UK Information Commissioner’s Office may be contacted here, and a list of the German state data protection authorities is available here.</li>
            </ul>
            We request that before you lodge a complaint that you contact us directly in order to allow us an opportunity to work directly with you to resolve any concerns you may have.<br /><br />
          </div>

          <div className="title">Retention Period</div>
          <div>
            We retain Personal Information for as long as needed or permitted in light of the purpose(s) for which it was collected and consistent with applicable law. The criteria used to determine our retention periods are:<br /><br />
            <ul>
              <li>The length of time we have an ongoing relationship with you (for example, for as long as you have a business relationship with us) plus a reasonable period in light of the applicable statute of limitations;</li>
              <li>Whether there is an applicable legal obligation we are subject to (for example, certain laws require maintaining certain records regarding real property for a certain period of time before we can delete them); or</li>
              <li>Whether retention is advisable in light of our legal position (such as in regard to current or reasonably foreseeable litigation or regulatory investigations).</li>
            </ul>
            If reasonably necessary or required to meet legal or regulatory obligations, resolve disputes, prevent fraud and abuse, or enforce our Terms, we may also keep Personal Information longer than our above-listed criteria may suggest. Unless otherwise required by applicable law, we will take reasonable steps to destroy or permanently de-identify Personal Information we keep in our possession that is no longer needed for the purpose for which it was collected.<br /><br />
          </div>

          <div className="title">Data Security</div>
          <div>
            We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls.<br /><br />
            Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Website. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Website.<br /><br />
          </div>

          <div className="title">Changes to Our Privacy Policy</div>
          <div>
            It is our policy to post any changes we make to our privacy policy on this page. If we make material changes to how we treat our users’ personal information, we will notify you through a notice on the Website. The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting our Website and this privacy policy to check for any changes.<br /><br />
          </div>

          <div className="title">Contact Information</div>
          <div>
            To ask questions or comment about this privacy policy and our privacy practices, contact Ashley Hamaoui at <a href="mailto:info@theresidencespompanobeach.com" target="_blank">info@theresidencespompanobeach.com</a><br /><br />
          </div>
        </Copy>
      </LegalContent>
    </ContentWrap>
  );
};
export default Legal;
