import styled from "styled-components";
import {Link} from "gatsby";
import {mediaMax} from "../../helpers/MediaQueries";

export const ContentWrap = styled.div`
  padding: 10vh 4% 6%;
  ${mediaMax.tablet`
    padding: 10vh 24px 60px;
  `}
`;

export const Logo = styled(Link)`
  display: block;
  max-width: 280px;
  width: 100%;
  margin: 0 auto 10vh;
  ${mediaMax.tablet`
    max-width: 265px;
    margin: 0 auto 50px;
  `}
`;

export const LegalContent = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1640px;
  margin: 0 auto;
  h2 {
    color: #000000;
  }
  ${mediaMax.tablet`
    flex-direction: column;
    gap: 40px;
    h2 {
      text-align: center;
    }
  `};
`;

export const Copy = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 74.756%;
  font-size: 18px;
  color: #000000;
  line-height: 2;
  letter-spacing: 0.4px;
  .title {
    font-size: 24px;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: 0.48px;
    text-transform: uppercase;
  }
  ul {
    margin: 0 0 36px 0;
    padding: 0 0 0 20px;
  }
  ul li {
    margin: 0;
  }
  a {
    text-decoration: underline;
    color: #000000;
    &:hover {
      text-decoration: none;
    }
  }
  ${mediaMax.tablet`
    width: 100%;
    font-size: 14px;
    .title {
      font-size: 18px;
    }
    ul {
      padding: 0 0 0 16px;
    }
  `};
`;